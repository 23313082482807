import React from 'react';
import '../../App.css';
import HeroSection from '../homepage/HeroSection'; // Assume this is critical and needs to load immediately
import AboutUsV2 from '../homepage/AboutUsV2'; // Assume this is critical and needs to load immediately
import Cards from '../homepage/Cards';
import Footer from '../homepage/Footer';
import TaxSavingsCarousel from '../homepage/TaxSavingsCarousel';

function Home() {
  return (
    <>
      <HeroSection />
      <AboutUsV2 />
      {/* <Stats/> */}
      <Cards/>
      <TaxSavingsCarousel/>
      <Footer/>
    </>
  );
}

export default Home;