import React, { useState } from 'react';
import './Footer.css';
import { Button } from './Button';
import emailjs from '@emailjs/browser';
import { Phone, Mail } from 'react-feather';

function Footer() {
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [companyValue, setCompanyValue] = useState('');
  const [titleValue, setTitleValue] = useState('');
  const [messageValue, setMessageValue] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleInputChange = (setValue) => (event) => setValue(event.target.value);

  const sendEmail = (e) => {
    e.preventDefault();
    const serviceID = 'service_n9409zq';
    const templateID = 'template_59i8k4h';
    const userID = '3XTtGwrnbB9EKekY6';

    const templateParams = {
      firstNameValue,
      lastNameValue,
      emailValue,
      phoneValue,
      titleValue,
      companyValue,
      messageValue,
    };

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('Email sent successfully', response);
        setIsEmailSent(true);
      })
      .catch((error) => {
        console.error('Email not sent, error:', error);
      });
  };

  return (
    <div className="footer-container" id="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">CONTACT US</p>
        <p className="footer-subscription-about">
          We are glad you are considering Slate Property Tax Solutions to be your property tax partner. For more
          information or to contact our team, please fill out the contact us form below and we will reach out to you
          directly. Alternatively, please feel free to reach out to any one of our team members directly. Individual
          contact information is located above in the Meet Our Leadership section of the webpage. We look forward to speaking
          with you.
        </p>

        <div className="large-icon-container">
          <div className="icon-container">
            <Phone className="icon" alt="Phone Icon" />
            <a className="icon-text" href="tel:817-985-7784">
              817-985-7784
            </a>
          </div>
          <div className="icon-container">
            <Mail className="icon" alt="Email Icon" />
            <a className="icon-text" href="mailto:propertytax@slatepts.com">
              PropertyTax@slatepts.com
            </a>
          </div>
        </div>

        {isEmailSent ? (
          <div className="email-sent-message">
            <p>A member of our team will be in touch!</p>
          </div>
        ) : (
          <div className="input-areas">
            <form className="form-container" onSubmit={sendEmail}>
              <div className="fields-container">
                <input
                  className="footer-input"
                  name="firstNameValue"
                  type="name"
                  placeholder="First Name *"
                  onChange={handleInputChange(setFirstNameValue)}
                  value={firstNameValue}
                />
                <input
                  className="footer-input"
                  name="lastNameValue"
                  type="name"
                  placeholder="Last Name *"
                  onChange={handleInputChange(setLastNameValue)}
                  value={lastNameValue}
                />
                <input
                  className="footer-input"
                  name="companyValue"
                  type="text"
                  placeholder="Company *"
                  onChange={handleInputChange(setCompanyValue)}
                  value={companyValue}
                />
                <input
                  className="footer-input"
                  name="titleValue"
                  type="title"
                  placeholder="Job Title"
                  onChange={handleInputChange(setTitleValue)}
                  value={titleValue}
                />
                <input
                  className="footer-input"
                  name="emailValue"
                  type="email"
                  placeholder="Email *"
                  onChange={handleInputChange(setEmailValue)}
                  value={emailValue}
                />
                <input
                  className="footer-input"
                  name="phoneValue"
                  type="phonenumber"
                  placeholder="Phone Number *"
                  onChange={handleInputChange(setPhoneValue)}
                  value={phoneValue}
                />
              </div>
              <textarea
                className="footer-input-message"
                name="messageValue"
                type="message"
                placeholder="Message (optional)"
                onChange={handleInputChange(setMessageValue)}
                value={messageValue}
                rows={4}
              />
              <Button
                buttonStyle="btn--outline"
                buttonSize="btn--large"
                type="submit"
                value="Send"
                disabled={!firstNameValue || !lastNameValue || !emailValue || !phoneValue || !companyValue}
              >
                Submit
              </Button>
            </form>
          </div>
        )}
      </section>
    </div>
  );
}

export default Footer;
